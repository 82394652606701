import type { MantineThemeColors } from "@mantine/core";
import { useComputedColorScheme, useMantineTheme } from "@mantine/core";

import { usePrimaryShade } from "./usePrimaryShade";

export interface CommonColors {
  // Special colors
  primaryColor: string;
  text: string;
  body: string;
  bodyLight: string;
  white: string;
  black: string;
  grayLight: string;
  darkLight: string;
  error: string;

  // Primary colors with primary shade
  darkPrimary: string;
  grayPrimary: string;
  redPrimary: string;
  pinkPrimary: string;
  grapePrimary: string;
  violetPrimary: string;
  indigoPrimary: string;
  bluePrimary: string;
  cyanPrimary: string;
  tealPrimary: string;
  greenPrimary: string;
  limePrimary: string;
  yellowPrimary: string;
  orangePrimary: string;

  // Base colors
  colors: MantineThemeColors;

  // Other
  whiteAndBlack: string;
}

export function useCommonColors(): CommonColors {
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme();
  const primaryShade = usePrimaryShade();
  return {
    // Special colors
    primaryColor: theme.colors[theme.primaryColor]![primaryShade],
    // https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/core/src/core/MantineProvider/global.css
    text: "var(--mantine-color-text)",
    body: "var(--mantine-color-body)",
    bodyLight: computedColorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    white: "FFFFFF",
    black: "000000",
    grayLight: "var(--mantine-color-gray-light-color)",
    darkLight: "var(--mantine-color-dark-light-color)",
    error: "var(--mantine-color-error)",

    // Primary colors with primary shade
    darkPrimary: theme.colors.dark[primaryShade],
    grayPrimary: theme.colors.gray[primaryShade],
    redPrimary: theme.colors.red[primaryShade],
    pinkPrimary: theme.colors.pink[primaryShade],
    grapePrimary: theme.colors.grape[primaryShade],
    violetPrimary: theme.colors.violet[primaryShade],
    indigoPrimary: theme.colors.indigo[primaryShade],
    bluePrimary: theme.colors.blue[primaryShade],
    cyanPrimary: theme.colors.cyan[primaryShade],
    tealPrimary: theme.colors.teal[primaryShade],
    greenPrimary: theme.colors.green[primaryShade],
    limePrimary: theme.colors.lime[primaryShade],
    yellowPrimary: theme.colors.yellow[primaryShade],
    orangePrimary: theme.colors.orange[primaryShade],

    // Base colors
    colors: theme.colors,

    // Other
    whiteAndBlack: computedColorScheme === "dark" ? theme.colors.dark[0] : theme.colors.dark[9],
  };
}
