import type { BoxProps } from "@mantine/core";
import { Anchor, Group, Container } from "@mantine/core";
import { useMemo, useCallback } from "react";
import { Link } from "react-router";

import { OctalogLogo } from "octalog-base";

import classes from "./Footer.module.css";

const createLinks = (external: boolean) => {
  const prefix = external ? "https://octalog.de/" : "/";
  return [
    { link: `${prefix}impressum`, label: "Impressum" },
    { link: `${prefix}privacy-policy`, label: "Datenschutzerklärung" },
  ];
};

export const FOOTER_HEIGHT_BASE = 40;
export const FOOTER_HEIGHT_LG = 50;

export interface FooterProps extends BoxProps {
  external?: boolean;
}

export function Footer({ external = true, ...rest }: FooterProps) {
  const links = useMemo(() => createLinks(external), [external]);

  const renderLink = useCallback(
    (link: { link: string; label: string }) => (
      <Anchor c="dimmed" className={classes.link} component={Link} key={link.label} size="sm" to={link.link}>
        {link.label}
      </Anchor>
    ),
    [],
  );

  return (
    <Container className={classes.footer} h={{ base: FOOTER_HEIGHT_BASE, lg: FOOTER_HEIGHT_LG }} {...rest} px="md">
      <OctalogLogo size="90%" />
      <Group>{links.map((element) => renderLink(element))}</Group>
    </Container>
  );
}
