import { useScrollIntoView } from "@mantine/hooks";
import { useRef, useState } from "react";

export function useScrollLogic() {
  const [activeSection, setActiveSection] = useState("hero");
  const scrollOptions = {
    offset: 80,
    duration: 500,
  };
  const { scrollIntoView, targetRef: heroRef } = useScrollIntoView<HTMLDivElement>(scrollOptions);

  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const scrollToSection = (id: string) => {
    setActiveSection(id);
    if (id === "hero") {
      scrollIntoView({ alignment: "start" });
    } else {
      const sectionRef = sectionRefs.current[id];
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return { activeSection, setActiveSection, heroRef, sectionRefs, scrollToSection };
}
