import cx from "clsx";
import React from "react";

import classes from "./SectionTitle.module.css";

interface PageSectionTitleProps extends React.ComponentProps<"h1"> {
  type?: "white" | "default";
  position?: "left" | "right";
}

export function PageSectionTitle({
  children,
  className,
  type = "default",
  position = "left",
  ...others
}: PageSectionTitleProps) {
  return (
    <h2 className={cx(classes.title, className)} data-position={position} data-type={type} {...others}>
      {children}
    </h2>
  );
}
