import { Container } from "@mantine/core";

export function PrivacyPolicyPage() {
  return (
    <Container py="xl" size="md">
      <h1>Datenschutz&shy;erkl&auml;rung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>{" "}
      <p>
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten
        passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich
        identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer
        unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>{" "}
      <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>{" "}
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie
        dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.
      </p>{" "}
      <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um
        Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>{" "}
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
        erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
      </p>{" "}
      <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{" "}
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere
        Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden. Sofern &uuml;ber die Website
        Vertr&auml;ge geschlossen oder angebahnt werden k&ouml;nnen, werden die &uuml;bermittelten Daten auch f&uuml;r
        Vertragsangebote, Bestellungen oder sonstige Auftragsanfragen verarbeitet.
      </p>{" "}
      <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{" "}
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder
        L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
        k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
        Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
      </p>{" "}
      <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
      <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{" "}
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit
        sogenannten Analyseprogrammen.
      </p>{" "}
      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.
      </p>
      <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
      <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
      <h3>Hetzner</h3>{" "}
      <p>Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).</p>{" "}
      <p>
        Details entnehmen Sie der Datenschutzerkl&auml;rung von Hetzner:{" "}
        <a href="https://www.hetzner.com/de/legal/privacy-policy/" rel="noopener noreferrer" target="_blank">
          https://www.hetzner.com/de/legal/privacy-policy/
        </a>
        .
      </p>{" "}
      <p>
        Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
        Interesse an einer m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
        a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <h4>Auftragsverarbeitung</h4>{" "}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
        geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen
        und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Cloudflare</h3>{" "}
      <p>
        Wir nutzen den Service &bdquo;Cloudflare&ldquo;. Anbieter ist die Cloudflare Inc., 101 Townsend St., San
        Francisco, CA 94107, USA (im Folgenden &bdquo;Cloudflare&rdquo;).
      </p>{" "}
      <p>
        Cloudflare bietet ein weltweit verteiltes Content Delivery Network mit DNS an. Dabei wird technisch der
        Informationstransfer zwischen Ihrem Browser und unserer Website &uuml;ber das Netzwerk von Cloudflare geleitet.
        Das versetzt Cloudflare in die Lage, den Datenverkehr zwischen Ihrem Browser und unserer Website zu analysieren
        und als Filter zwischen unseren Servern und potenziell b&ouml;sartigem Datenverkehr aus dem Internet zu dienen.
        Hierbei kann Cloudflare auch Cookies oder sonstige Technologien zur Wiedererkennung von Internetnutzern
        einsetzen, die jedoch allein zum hier beschriebenen Zweck verwendet werden.
      </p>{" "}
      <p>
        Der Einsatz von Cloudflare beruht auf unserem berechtigten Interesse an einer m&ouml;glichst fehlerfreien und
        sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
      </p>{" "}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
        Details und weitere Informationen zum Thema Sicherheit und Datenschutz bei Cloudflare finden Sie hier:{" "}
        <a href="https://www.cloudflare.com/privacypolicy/" rel="noopener noreferrer" target="_blank">
          https://www.cloudflare.com/privacypolicy/
        </a>
        .
      </p>
      <p>
        Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo;
        (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung
        europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach
        dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
        Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{" "}
        <a href="https://www.dataprivacyframework.gov/participant/5666" rel="noopener noreferrer" target="_blank">
          https://www.dataprivacyframework.gov/participant/5666
        </a>
        .
      </p>
      <h4>Auftragsverarbeitung</h4>{" "}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
        geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen
        und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
      <h3>Datenschutz</h3>{" "}
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
        personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerkl&auml;rung.
      </p>{" "}
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
        Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung
        erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu
        welchem Zweck das geschieht.
      </p>{" "}
      <p>
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
        Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht m&ouml;glich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
      <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>{" "}
      <p>
        Marcel Richter
        <br />
        Beckerstr. 33
        <br />
        64289 Darmstadt
      </p>
      <p>
        Telefon: +49 (0) 175 4522 837
        <br />
        E-Mail: info@octalog.de
      </p>
      <p>
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen
        &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen
        o. &Auml;.) entscheidet.
      </p>
      <h3>Speicherdauer</h3>{" "}
      <p>
        Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
        personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein
        berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden
        Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die
        Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
      </p>
      <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>{" "}
      <p>
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach
        Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
        personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49
        Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
        Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
        zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre
        Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
        verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
        Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art.
        6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art.
        6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in
        den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
      </p>
      <h3>Empfänger von personenbezogenen Daten</h3>{" "}
      <p>
        Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei
        ist teilweise auch eine &Uuml;bermittlung von personenbezogenen Daten an diese externen Stellen erforderlich.
        Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer
        Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von
        Daten an Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der
        Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
        Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines g&uuml;ltigen
        Vertrags &uuml;ber Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag
        &uuml;ber gemeinsame Verarbeitung geschlossen.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
      <p>
        Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie
        k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum
        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
      </h3>{" "}
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS
        RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
        GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
        DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE
        VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT
        DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
        DSGVO).
      </p>{" "}
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
        JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
        WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
        STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
        DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
      </p>
      <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>{" "}
      <p>
        Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
        Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
        Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
        anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
      </p>
      <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{" "}
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
        automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
        aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.
      </p>
      <h3>Auskunft, Berichtigung und L&ouml;schung</h3>{" "}
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
        &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren
        Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
      </p>
      <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{" "}
      <p>
        Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
        k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in
        folgenden F&auml;llen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in
          der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die
          Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie
          statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
          Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
          L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren
          und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen,
          haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten
        &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung,
        Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der
        Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>
      <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{" "}
      <p>
        Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum
        Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.
      </p>{" "}
      <p>
        Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
        &uuml;bermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von
        nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-E-Mails, vor.
      </p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>{" "}
      <p>
        Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und richten
        auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer
        Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t gespeichert.
        Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem
        Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen oder eine automatische L&ouml;schung durch Ihren
        Webbrowser erfolgt.
      </p>{" "}
      <p>
        Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies).
        Third-Party-Cookies erm&ouml;glichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb
        von Webseiten (z.&nbsp;B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
        Webseitenfunktionen ohne diese nicht funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die
        Anzeige von Videos). Andere Cookies k&ouml;nnen zur Auswertung des Nutzerverhaltens oder zu Werbezwecken
        verwendet werden.
      </p>{" "}
      <p>
        Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von
        Ihnen erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website
        (z.&nbsp;B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage
        von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
        und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
        auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG); die Einwilligung ist
        jederzeit widerrufbar.
      </p>{" "}
      <p>
        Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und
        Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell
        ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren.
        Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
      </p>{" "}
      <p>
        Welche Cookies und Dienste auf dieser Website eingesetzt werden, k&ouml;nnen Sie dieser
        Datenschutzerkl&auml;rung entnehmen.
      </p>
      <h3>Server-Log-Dateien</h3>{" "}
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
        Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>{" "}
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
        berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash;
        hierzu m&uuml;ssen die Server-Log-Files erfasst werden.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
        hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>{" "}
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
        erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse
        an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
        widerrufbar.
      </p>{" "}
      <p>
        Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur
        L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
        Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
      </p>
      <h3>Registrierung auf dieser Website</h3>{" "}
      <p>
        Sie k&ouml;nnen sich auf dieser Website registrieren, um zus&auml;tzliche Funktionen auf der Seite zu nutzen.
        Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes,
        f&uuml;r den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben m&uuml;ssen
        vollst&auml;ndig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
      </p>{" "}
      <p>
        F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder bei technisch notwendigen &Auml;nderungen nutzen
        wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
      </p>{" "}
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchf&uuml;hrung des durch
        die Registrierung begr&uuml;ndeten Nutzungsverh&auml;ltnisses und ggf. zur Anbahnung weiterer Vertr&auml;ge
        (Art. 6 Abs. 1 lit. b DSGVO).
      </p>{" "}
      <p>
        Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website registriert
        sind und werden anschlie&szlig;end gel&ouml;scht. Gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
      </p>
      <h2>5. Newsletter</h2>
      <h3>Newsletter&shy;daten</h3>{" "}
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine
        E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der
        angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden
        nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r den
        Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
      </p>{" "}
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf
        Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten,
        der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
        &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten
        Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
      </p>{" "}
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung
        aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des
        Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor,
        E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses
        nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.
      </p>{" "}
      <p>Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unber&uuml;hrt.</p>{" "}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
        Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung k&uuml;nftiger
        Mailings erforderlich ist. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit
        anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
        Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6
        Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.{" "}
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
          &uuml;berwiegen.
        </strong>
      </p>
      <h3>Newsletterversand an Bestandskunden</h3>{" "}
      <p>
        Wenn Sie Waren oder Dienstleistungen bei uns bestellen und hierbei Ihre E-Mail-Adresse hinterlegen, kann diese
        E-Mail-Adresse in der Folge durch uns f&uuml;r den Versand von Newslettern verwendet werden, sofern wir Sie
        vorab hier&uuml;ber informieren. In einem solchen Fall wird &uuml;ber den Newsletter nur Direktwerbung f&uuml;r
        eigene &auml;hnliche Waren oder Dienstleistungen versendet. Die Zusendung dieses Newsletters kann von Ihnen
        jederzeit gek&uuml;ndigt werden. Zu diesem Zweck findet sich in jedem Newsletter ein entsprechender Link.
        Rechtsgrundlage f&uuml;r den Versand des Newsletters ist in diesem Fall Art. 6 Abs. 1 lit. f DSGVO in Verbindung
        mit &sect; 7 Abs. 3 UWG.
      </p>{" "}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns ggf. in einer Blacklist
        gespeichert, um k&uuml;nftige Mailings an Sie zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r
        diesen Zweck verwendet und nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als
        auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
        Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
        befristet. Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
        &uuml;berwiegen.
      </p>
      <h2>6. Plugins und Tools</h2>
      <h3>Mapbox</h3>{" "}
      <p>
        Wir haben Mapbox auf dieser Website eingebunden. Anbieter ist Mapbox, Inc. 740 15th Street NW, 5th Floor,
        Washington, District of Columbia 20005, USA (nachfolgende &bdquo;Mapbox&ldquo;). Mit Hilfe dieses Dienstes
        k&ouml;nnen wir Kartenmaterial auf unserer Website einbinden.
      </p>{" "}
      <p>
        Zur Nutzung der Funktionen von Mapbox ist es notwendig, Ihre IP-Adresse, Ihre User Agent und noch weitere Daten
        zu speichern. Diese Informationen werden in der Regel an einen Server von Microsoft in den USA &uuml;bertragen
        und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Daten&uuml;bertragung.
      </p>{" "}
      <p>
        Dies stellt seitens des Anbieters ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
        Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder
        den Zugriff auf Informationen im Endger&auml;t des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG
        umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>{" "}
      <p>
        Weitere Details entnehmen Sie der Datenschutzerkl&auml;rung des Anbieters unter{" "}
        <a href="https://www.mapbox.com/legal/privacy" rel="noopener noreferrer" target="_blank">
          https://www.mapbox.com/legal/privacy
        </a>
        .
      </p>
      <p>
        Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo;
        (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung
        europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach
        dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
        Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{" "}
        <a href="https://www.dataprivacyframework.gov/participant/5640" rel="noopener noreferrer" target="_blank">
          https://www.dataprivacyframework.gov/participant/5640
        </a>
        .
      </p>
      <h3>Cloudflare Turnstile</h3>{" "}
      <p>
        Wir nutzen Cloudflare Turnstile (im Folgenden &bdquo;Turnstile&ldquo;) auf dieser Website. Anbieter ist die
        Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107, USA (im Folgenden &bdquo;Cloudflare&rdquo;).
      </p>{" "}
      <p>
        Mit Turnstile soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf dieser Website (z.&nbsp;B. in einem
        Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
        Turnstile das Verhalten des Websitebesuchers anhand verschiedener Merkmale.
      </p>
      <p>
        Diese Analyse beginnt automatisch, sobald der Websitebesucher eine Website mit aktiviertem Turnstile betritt.
        Zur Analyse wertet Turnstile verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des
        Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte Mausbewegungen). Die bei der Analyse erfassten
        Daten werden an Cloudflare weitergeleitet.
      </p>{" "}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
        hat ein berechtigtes Interesse daran, seine Webangebote vor missbr&auml;uchlicher automatisierter
        Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
        die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des
        Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit
        widerrufbar.
      </p>{" "}
      <p>
        Die Datenverarbeitung wird auf Standardvertragsklauseln gest&uuml;tzt, die sie hier finden:{" "}
        <a href="https://www.cloudflare.com/cloudflare-customer-scc/" rel="noopener noreferrer" target="_blank">
          https://www.cloudflare.com/cloudflare-customer-scc/
        </a>
        .
      </p>{" "}
      <p>
        Weitere Informationen zu Cloudflare Turnstile entnehmen Sie den Datenschutzbestimmungen unter{" "}
        <a href="https://www.cloudflare.com/cloudflare-customer-dpa/" rel="noopener noreferrer" target="_blank">
          https://www.cloudflare.com/cloudflare-customer-dpa/
        </a>
        .
      </p>
      <p>
        Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo;
        (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung
        europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach
        dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
        Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{" "}
        <a href="https://www.dataprivacyframework.gov/participant/5666" rel="noopener noreferrer" target="_blank">
          https://www.dataprivacyframework.gov/participant/5666
        </a>
        .
      </p>
      <h3>Sentry</h3>{" "}
      <p>
        Wir haben Sentry auf dieser Website eingebunden. Anbieter ist die Functional Software Inc., 45 Fremont Street,
        8th Floor, San Francisco, California 94105, USA (nachfolgend Sentry).
      </p>{" "}
      <p>
        Sentry ist ein Dienst zur Open-Source-Fehlerverfolgung und erm&ouml;glicht uns, Fehler und Abst&uuml;rze an
        beliebigen Stellen in einer webbasierten Software in Echtzeit zu &uuml;berwachen und zu beheben.
      </p>{" "}
      <p>
        Die Verwendung von Sentry erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
        berechtigtes Interesse an einer fehlerfreien Funktion der eigenen Website.
      </p>{" "}
      <p>
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. f&uuml;r
        Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>{" "}
      <p>
        Weitere Details entnehmen Sie der Datenschutzerkl&auml;rung des Anbieters unter{" "}
        <a href="https://sentry.io/privacy/" rel="noopener noreferrer" target="_blank">
          https://sentry.io/privacy/
        </a>
        .
      </p>
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
        Details finden Sie hier:{" "}
        <a
          href="https://sentry.io/legal/dpa/5.0.0/#cross-border-transfer-mechanisms"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://sentry.io/legal/dpa/5.0.0/#cross-border-transfer-mechanisms
        </a>{" "}
        und{" "}
        <a href="https://sentry.io/legal/dpa/5.0.0/#third-party" rel="noopener noreferrer" target="_blank">
          https://sentry.io/legal/dpa/5.0.0/#third-party
        </a>
        .
      </p>
      <p>
        Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo;
        (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung
        europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach
        dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
        Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{" "}
        <a href="https://www.dataprivacyframework.gov/participant/5869" rel="noopener noreferrer" target="_blank">
          https://www.dataprivacyframework.gov/participant/5869
        </a>
        .
      </p>
      <h4>Auftragsverarbeitung</h4>{" "}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
        geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen
        und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h2>7. eCommerce und Zahlungs&shy;anbieter</h2>
      <h3>Verarbeiten von Kunden- und Vertragsdaten</h3>{" "}
      <p>
        Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und Vertragsdaten zur Begr&uuml;ndung, inhaltlichen
        Ausgestaltung und &Auml;nderung unserer Vertragsbeziehungen. Personenbezogene Daten &uuml;ber die
        Inanspruchnahme dieser Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich
        ist, um dem Nutzer die Inanspruchnahme des Dienstes zu erm&ouml;glichen oder abzurechnen. Rechtsgrundlage
        hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO.
      </p>{" "}
      <p>
        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Gesch&auml;ftsbeziehung und
        Ablauf der ggf. bestehenden gesetzlichen Aufbewahrungsfristen gel&ouml;scht. Gesetzliche Aufbewahrungsfristen
        bleiben unber&uuml;hrt.
      </p>
      <h3>Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r Dienstleistungen und digitale Inhalte</h3>
      <p>
        Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
        notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
      </p>{" "}
      <p>
        Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der &Uuml;bermittlung
        ausdr&uuml;cklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche Einwilligung,
        etwa zu Zwecken der Werbung, erfolgt nicht.
      </p>{" "}
      <p>
        Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
        Erf&uuml;llung eines Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.
      </p>
    </Container>
  );
}
