import { ActionIcon, Group, Tooltip, useMantineColorScheme } from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons-react";

export function ColorSchemeToggle() {
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <Group gap="xs">
      <Tooltip label={colorScheme === "dark" ? "Zum hellen Design wechseln" : "Zum dunklen Design wechseln"}>
        <ActionIcon
          onClick={() => setColorScheme(colorScheme === "dark" ? "light" : "dark")}
          size="lg"
          variant="default"
        >
          {colorScheme === "dark" ? <IconSun size="1.2rem" /> : <IconMoon size="1.2rem" />}
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
