import {
  IconAddressBook,
  IconBriefcase2,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconCalculator,
  IconChartBar,
  IconChartHistogram,
  IconClipboardList,
  IconClockHour4,
  IconCode,
  IconCoinEuro,
  IconDeviceMobile,
  IconHeadset,
  IconHistory,
  IconLayout,
  IconMapPin,
  IconPackage,
  IconPlugConnected,
  IconRefresh,
  IconReportMoney,
  IconRoute,
  IconScale,
  IconSearch,
  IconSwitchHorizontal,
  IconTruckDelivery,
  IconUserCircle,
  IconUsers,
} from "@tabler/icons-react";
import type { IconType } from "octalog-base";

import type { FeaturesPageSectionProps } from "../../components/FeaturePageSection/FeaturesPageSection";
import type { PageSectionProps } from "../../components/PageSection/PageSection";
import contactLight from "../../img/new_contact_light.png";
import orderLight from "../../img/order_light.png";
import pricesLight from "../../img/prices_light.png";
import statsLight from "../../img/stats_light.png";
import mobileLight from "../../img/stats_volume_light.png";
import stockLight from "../../img/stock_light.png";
import syncLight from "../../img/sync_light.png";

export interface ExtendedPageSectionProps extends Omit<PageSectionProps, "sectionRefs" | "children"> {
  feature: ExtendedFeaturesPageSectionProps;
}

export interface ExtendedFeaturesPageSectionProps extends FeaturesPageSectionProps {
  icon: IconType;
}

export function useFeaturesSections(): ExtendedPageSectionProps[] {
  return [
    {
      id: "stock-management",
      feature: {
        title: "Lagerverwaltung",
        description: "Professionelles Bestandsmanagement für Ihr Brennholzgeschäft",
        imageProps: {
          src: stockLight,
        },
        icon: IconBuildingWarehouse,
        mode: "horizontal",
        features: [
          {
            title: "Ein- und Ausbuchen von Behältern",
            description:
              "Einfaches Ein- und Ausbuchen von Behältern mit detaillierten Informationen zu Holzarten, Schnittlängen und Trocknungsgraden.",
            Icon: IconPackage,
          },
          {
            title: "Lagerplatz-Übersicht",
            description:
              "Übersichtliche Verwaltung verschiedener Lagerplätze mit Live-Tracking aller Bestände pro Standort.",
            Icon: IconMapPin,
          },
          {
            title: "Echtzeitinformationen",
            description: "Jederzeit wissen, was, wie groß und wo Ihre Lagerbestände sind.",
            Icon: IconClockHour4,
          },
          {
            title: "Bestandswertermittlung",
            description: "Lagerbestands-Reports mit Details zu Holzarten, Schnittlängen, Mengen und Bestandswert.",
            Icon: IconReportMoney,
          },
        ],
      },
    },
    {
      id: "contact-management",
      alt: true,
      feature: {
        title: "Kontaktverwaltung",
        description: "Übersichtliche Verwaltung Ihrer Kundenbeziehungen",
        imageProps: {
          src: contactLight,
        },
        icon: IconUsers,
        mode: "vertical",
        reverse: true,
        features: [
          {
            title: "Automatische Adressvervollständigung",
            description: "Effiziente Kontakterstellung durch intelligente Adressvorschläge und Validierung.",
            Icon: IconAddressBook,
          },
          {
            title: "Synchronisation mit externen Systemen",
            description:
              "Automatische Synchronisation Ihrer Kundendaten mit Ihrer Buchhaltungssoftware wie z.B. Lexware.",
            Icon: IconRefresh,
          },
          {
            title: "Kundenhistorie",
            description: "Vollständiger Überblick über alle Kundeninteraktionen, Aufträge und Notizen.",
            Icon: IconUsers,
          },
          {
            title: "Intuitive Kontaktsuche",
            description:
              "Intelligente Suche, die alle Daten eines Kontakts durchsucht, z.B. 'Maier Frankfurt' findet Kontakte mit Namen 'Maier' und Adresse 'Frankfurt'.",
            Icon: IconSearch,
          },
        ],
      },
    },
    {
      id: "order-management",
      feature: {
        title: "Auftragsverwaltung",
        description: "Effiziente Verwaltung vom Angebot bis zur Auslieferung",
        imageProps: {
          src: orderLight,
        },
        icon: IconClipboardList,
        mode: "vertical",
        features: [
          {
            title: "Smarte Auftragserfassung",
            description:
              "Schnelle Auftragserstellung durch intelligente Kontaktsuche, die alle Daten eines Kontakts durchsucht.",
            Icon: IconSearch,
          },
          {
            title: "Automatische Preisberechnung",
            description:
              "Erstellen Sie Angebote und Aufträge mit automatischer Preisberechnung basierend auf Ihren hinterlegten Preismodellen.",
            Icon: IconCalculator,
          },
          {
            title: "Auftragshistorie der Kunden",
            description:
              "Greifen Sie auf die Auftragshistorie, Preisvereinbarungen und Notizen zu jedem Kunden bei der Auftragserfassung zu.",
            Icon: IconHistory,
          },
          {
            title: "Auftragsübersicht",
            description: "Zentrale Verwaltung aller Aufträge mit umfangreichen Filter- und Sortiermöglichkeiten.",
            Icon: IconClipboardList,
          },
          {
            title: "Lieferplanung",
            description: "Effiziente Organisation und Planung Ihrer Auslieferungen.",
            Icon: IconTruckDelivery,
          },
          {
            title: "Routenoptimierung",
            description:
              "Optimieren Sie Ihre Auslieferungsrouten für ähnliche Lieferorte, um Zeit und Kosten zu sparen.",
            Icon: IconRoute,
          },
        ],
      },
    },
    {
      id: "analytics-and-statistics",
      alt: true,
      feature: {
        title: "Geschäftsanalyse & Statistiken",
        description: "Datenbasierte Entscheidungen für Ihren Geschäftserfolg",
        imageProps: {
          src: statsLight,
        },
        icon: IconChartHistogram,
        mode: "vertical",
        reverse: true,
        features: [
          {
            title: "Ein- und Ausgangsanalyse",
            description:
              "Erhalten Sie Auswertungen über Warenbewegungen im Lager und analysieren Sie saisonale Trends.",
            Icon: IconSwitchHorizontal,
          },
          {
            title: "Bestandsreporting",
            description: "Umfassende Reports zu Lagerbeständen und aktuellem Warenwert.",
            Icon: IconChartBar,
          },
          {
            title: "Auftragsstatistiken",
            description: "Wertvolle Analysen Ihrer Aufträge und Verkaufszahlen.",
            Icon: IconChartHistogram,
          },
        ],
      },
    },
    {
      id: "pricing-management",
      feature: {
        title: "Preisverwaltung",
        description: "Smarte Unterstützung bei der Verwaltung Ihrer Preise",
        imageProps: {
          src: pricesLight,
        },
        icon: IconCoinEuro,
        mode: "vertical",
        features: [
          {
            title: "Flexible Preismodelle",
            description: "Verwalten Sie Preise nach Holzarten, Schnittlängen und Trocknungsgraden.",
            Icon: IconScale,
          },
          {
            title: "Automatische Preisberechnung",
            description: "Lassen Sie Auftragspreise automatisch basierend auf Ihren Preismodellen berechnen.",
            Icon: IconCalculator,
          },
          {
            title: "Kundenspezifische Preise",
            description: "Definieren Sie individuelle Preise für Stammkunden oder Großabnehmer.",
            Icon: IconUserCircle,
          },
        ],
      },
    },
    {
      id: "user-friendly",
      alt: true,
      feature: {
        title: "Benutzerfreundlichkeit",
        description: "Intuitive Bedienung für effizientes Arbeiten",
        imageProps: {
          src: mobileLight,
        },
        icon: IconDeviceMobile,
        mode: "vertical",
        reverse: true,
        features: [
          {
            title: "Responsive Design",
            description: "Optimierte Darstellung auf allen Geräten - vom Desktop bis zum Smartphone.",
            Icon: IconDeviceMobile,
          },
          {
            title: "Intuitive Benutzeroberfläche",
            description: "Einfache Navigation und übersichtliche Darstellung aller Funktionen.",
            Icon: IconLayout,
          },
          {
            title: "Verlässlicher Support",
            description: "Wir wissen wie wichtig es ist, dass Sie hilfe bekommen, wenn es nötig ist.",
            Icon: IconHeadset,
          },
          {
            title: "Branchenexpertise",
            description:
              "Entwickelt von Brennholzprofis für Brennholzprofis. Jahrelange praktische Erfahrung. Maßgeschneidert für Ihre Anforderungen.",
            Icon: IconBriefcase2,
          },
        ],
      },
    },
    {
      id: "integration-and-future-proofing",
      feature: {
        title: "Integration und Zukunftssicherheit",
        description: "Nahtlose Verbindung zu externen Systemen und kontinuierliche Weiterentwicklung",
        imageProps: {
          src: syncLight,
        },
        icon: IconRefresh,
        mode: "vertical",
        features: [
          {
            title: "Buchhaltungsintegration",
            description: "Anbindung an externe Buchhaltungssysteme wie Lexware für eine nahtlose Datensynchronisation.",
            Icon: IconPlugConnected,
          },
          {
            title: "Geplante CRM-Integration",
            description: "Zukünftige Einbindung von CRM-Systemen für eine umfassende Kundenverwaltung.",
            Icon: IconBuildingStore,
          },
          {
            title: "Kontinuierliche Weiterentwicklung",
            description: "Regelmäßige Updates und neue Features, um Ihren Geschäftserfolg langfristig zu sichern.",
            Icon: IconCode,
          },
        ],
      },
    },
  ];
}
