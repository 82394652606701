import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router";

import { useScrollLogic } from "./hooks/useScrollLogic";
import { Layout } from "./Layout";
import { HomePage } from "./pages/Home/Home.page";
import { ImpressumPage } from "./pages/Impressum/Impressum.page";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy/PrivacyPolicy.page";

export function Router() {
  const { activeSection, heroRef, sectionRefs, setActiveSection } = useScrollLogic();

  useEffect(() => {
    // Function to handle the scrolling
    const scrollToHash = () => {
      const hash = globalThis.location.hash;
      if (hash) {
        // Remove the '#' symbol
        const id = hash.slice(1);
        const element = document.getElementById(id);

        if (element) {
          // Add a small delay to ensure content is rendered
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 100);
        }
      }
    };

    // Scroll on the initial load
    scrollToHash();

    // Listen for hash changes while using the app
    globalThis.addEventListener("hashchange", scrollToHash);

    // Cleanup
    return () => globalThis.removeEventListener("hashchange", scrollToHash);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout activeSection={activeSection} />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <HomePage heroRef={heroRef} sectionRefs={sectionRefs} setActiveSection={setActiveSection} />,
          errorElement: <ErrorBoundary />,
        },
        {
          path: "impressum",
          element: <ImpressumPage />,
          errorElement: <ErrorBoundary />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicyPage />,
          errorElement: <ErrorBoundary />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
