import { useCommonColors } from "../../mantine";

import type { LogoProps } from "./OctalogLogo";

export function useOctalogLogoColors({ color }: LogoProps) {
  const commonColors = useCommonColors();
  return {
    color: color || commonColors.text,
  };
}
