/* eslint-disable react/jsx-max-depth --- max depth is fine */
import {
  Anchor,
  Box,
  Burger,
  Button,
  Center,
  Collapse,
  Divider,
  Drawer,
  Group,
  HoverCard,
  rem,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
  VisuallyHidden,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { OctalogLogo, useCommonColors } from "octalog-base";
import { useNavigate } from "react-router";

import { useFeaturesSections } from "../../pages/Home/useFeatureSections";
import { ColorSchemeToggle } from "../ColorSchemeToggle/ColorSchemeToggle";

import classes from "./Header.module.css";

interface HeaderProps {
  activeSection: string;
}

export function Header({ activeSection }: HeaderProps) {
  const navigate = useNavigate();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();
  const commonColors = useCommonColors();

  const featuresSections = useFeaturesSections();

  const links = featuresSections.map((section) => {
    return (
      <UnstyledButton
        className={classes.subLink}
        component="a"
        data-active={section.id === activeSection || undefined}
        href={`#${section.id}`}
        key={`${section.id}-link`}
      >
        <Group align="flex-start" wrap="nowrap">
          <ThemeIcon radius="md" size={34} variant="default">
            <section.feature.icon color={commonColors.primaryColor} style={{ width: rem(22), height: rem(22) }} />
          </ThemeIcon>
          <div>
            <Text fw={500} size="sm">
              {section.feature.title}
            </Text>
            <Text c="dimmed" size="xs">
              {section.feature.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    );
  });

  return (
    <>
      <header className={classes.header}>
        <Group h="100%" justify="space-between">
          <Anchor aria-label="Mantine" href="/">
            <VisuallyHidden>Willkommen bei Octalog, der Lösung für Brennholzunternehmen</VisuallyHidden>
            <OctalogLogo onClick={() => navigate("/")} size={30} type="blank" />
          </Anchor>

          <Group gap={0} h="100%" visibleFrom="sm">
            <Anchor<"a">
              className={classes.link}
              data-active={"hero" === activeSection || undefined}
              href="#"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </Anchor>
            <HoverCard position="bottom" radius="md" shadow="md" width={600} withinPortal>
              <HoverCard.Target>
                <UnstyledButton className={classes.link} data-active={"hero" !== activeSection || undefined}>
                  <Center inline>
                    <Box component="span" mr={5}>
                      Features
                    </Box>
                    <IconChevronDown color={theme.colors.blue[6]} style={{ width: rem(16), height: rem(16) }} />
                  </Center>
                </UnstyledButton>
              </HoverCard.Target>

              <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                <SimpleGrid cols={2} spacing={0}>
                  {links}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Group visibleFrom="sm">
            <ColorSchemeToggle />
            <Button component="a" href="https://app.octalog.de/sign-in" target="_blank" variant="default">
              Einloggen
            </Button>
            <Button component="a" href="https://app.octalog.de/sign-up" target="_blank">
              Registrieren
            </Button>
          </Group>

          <Burger hiddenFrom="sm" onClick={toggleDrawer} opened={drawerOpened} />
        </Group>
      </header>

      <Drawer
        hiddenFrom="sm"
        onClose={closeDrawer}
        opened={drawerOpened}
        padding="md"
        size="100%"
        title="Navigation"
        zIndex={1_000_000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <Anchor<"a">
            className={classes.link}
            data-active={"hero" === activeSection || undefined}
            href="#"
            onClick={() => {
              navigate("/");
              closeDrawer();
            }}
          >
            Home
          </Anchor>
          <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Features
              </Box>
              <IconChevronDown color={theme.colors.blue[6]} style={{ width: rem(16), height: rem(16) }} />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>{links}</Collapse>

          <Divider my="sm" />

          <Group grow justify="center" pb="xl" px="md">
            <ColorSchemeToggle />
            <Button component="a" href="https://app.octalog.de/sign-in" target="_blank" variant="default">
              Einloggen
            </Button>
            <Button component="a" href="https://app.octalog.de/sign-up" target="_blank">
              Registrieren
            </Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}
