import { Container, createTheme, Paper } from "@mantine/core";

export const DEFAULT_ICON_SIZE = 16;
export const DEFAULT_SHADOW = "md";

// noinspection JSVoidFunctionReturnValueUsed
export const octalogTheme = createTheme({
  primaryColor: "green",
  primaryShade: {
    light: 7,
    dark: 7,
  },
  colors: {
    green: [
      "#E6F4ED",
      "#C4E5D4",
      "#A2D6BB",
      "#80C7A2",
      "#5EB889",
      "#3CA970",
      "#1A9A57",
      "#087443",
      "#065F35",
      "#044A28",
    ],
  },
  components: {
    Paper: Paper.extend({
      defaultProps: {
        shadow: DEFAULT_SHADOW,
      },
    }),
    Container: Container.extend({
      defaultProps: {
        size: "xl",
      },
    }),
  },
});
