import { Divider } from "@mantine/core";
import React from "react";

import { FeaturesPageSection } from "../../components/FeaturePageSection/FeaturesPageSection";
import { HeroHeader } from "../../components/HeroHeader/HeroHeader";
import { PageSection } from "../../components/PageSection/PageSection";

import { useFeaturesSections } from "./useFeatureSections";
import { useScrollEffect } from "./useScrollEffect";

export interface HomePageProps {
  setActiveSection: (id: string) => void;
  sectionRefs: React.RefObject<Record<string, HTMLDivElement | null>>;
  heroRef: React.RefObject<HTMLDivElement>;
}

export function HomePage({ setActiveSection, sectionRefs, heroRef }: HomePageProps) {
  const featuresSections = useFeaturesSections();
  useScrollEffect(setActiveSection, sectionRefs, heroRef, featuresSections);

  return (
    <>
      <HeroHeader heroRef={heroRef} />
      <Divider />
      {featuresSections.map((section, index) => {
        return (
          <PageSection
            alt={section.alt}
            divider={index !== featuresSections.length - 1}
            id={section.id}
            key={section.id}
            sectionRefs={sectionRefs}
          >
            <FeaturesPageSection
              description={section.feature.description}
              features={section.feature.features}
              imageProps={section.feature.imageProps}
              mode={section.feature.mode}
              reverse={section.feature.reverse}
              title={section.feature.title}
            />
          </PageSection>
        );
      })}
    </>
  );
}
