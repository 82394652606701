import type React from "react";
import { useEffect } from "react";

import type { ExtendedPageSectionProps } from "./useFeatureSections";

export function useScrollEffect(
  setActiveSection: (id: string) => void,
  sectionRefs: React.RefObject<Record<string, HTMLDivElement | null>>,
  heroRef: React.RefObject<HTMLDivElement>,
  featuresSections: ExtendedPageSectionProps[],
) {
  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: "hero", ref: heroRef.current },
        ...featuresSections.map((section) => ({
          id: section.id,
          ref: sectionRefs.current![section.id],
        })),
      ];

      const currentSection = sections.find((section) => {
        if (section.ref) {
          const rect = section.ref.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [featuresSections, heroRef, sectionRefs, setActiveSection]);
}
