import { Button, Container, Grid, Group, List, rem, Text, ThemeIcon, Title } from "@mantine/core";
import { IconArrowRight, IconCheck } from "@tabler/icons-react";
import { OctalogLogo, useCommonColors } from "octalog-base";
import React from "react";

import classes from "./HeroHeader.module.css";

const features = [
  {
    id: 1,
    title: "Lagerverwaltung leicht gemacht",
    description: "Jederzeit wissen, was, wie viel und wo Ihre Bestände sind – in Echtzeit.",
  },
  {
    id: 2,
    title: "Kunden- und Auftragsmanagement",
    description:
      "Effiziente Verwaltung von Kunden und Aufträgen – inklusive einfacher Preiskalkulation und Auftragshistorie.",
  },
  {
    id: 3,
    title: "Datenbasierte Entscheidungen",
    description: "Treffen Sie bessere Entscheidungen mit präzisen Statistiken und Berichten.",
  },
  {
    id: 4,
    title: "Integration und Kontaktsynchronisierung",
    description:
      "Synchronisieren Sie Ihre Kontakte und verknüpfen Sie externe Tools wie z.B. die Buchhaltungssoftware Lexware.",
  },
];

interface HeroHeaderProps {
  heroRef: React.RefObject<HTMLDivElement>;
}

export function HeroHeader({ heroRef }: HeroHeaderProps) {
  const commonColors = useCommonColors();
  return (
    <Container ref={heroRef}>
      <Grid className={classes.inner} gutter={0}>
        <Grid.Col order={{ base: 2, md: 1 }} span={{ base: 12, md: 7 }}>
          <div className={classes.content}>
            <Title className={classes.title} order={1}>
              Die <span className={classes.highlight}>Lösung</span> für <br /> Brennholz&shy;unternehmen
            </Title>
            <Text c="dimmed" mt="md" size="sm">
              <b>Informativ</b>, <b>komfortabel</b> und <b>jederzeit mobil verfügbar.</b> Die Verwaltungssoftware für
              Brennholzhändler, die alles einfacher macht.
            </Text>

            <List
              className={classes.featuresList}
              icon={
                <ThemeIcon radius="xl" size={20}>
                  <IconCheck stroke={1.5} style={{ width: rem(12), height: rem(12) }} />
                </ThemeIcon>
              }
              mt={30}
              size="sm"
              spacing="sm"
            >
              {features.map((feature) => (
                <List.Item className={classes.featureItem} key={feature.id}>
                  <b>{feature.title}</b> – {feature.description}
                </List.Item>
              ))}
            </List>
          </div>
        </Grid.Col>
        <Grid.Col order={{ base: 3 }} span={{ base: 12 }}>
          <Group>
            <Button
              className={`${classes.control} ${classes.getStartedButton}`}
              color={commonColors.primaryColor}
              component="a"
              href="https://app.octalog.de"
              radius="xl"
              rightSection={<IconArrowRight size={18} />}
              size="md"
              target="_blank"
            >
              Loslegen
            </Button>
            <Button
              className={classes.control}
              component="a"
              href="mailto:info@octalog.de"
              radius="xl"
              size="md"
              variant="default"
            >
              Kontakt
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col className={classes.logoColumn} order={{ base: 1, md: 2 }} span={{ base: 12, md: 5 }}>
          <OctalogLogo className={classes.logo} />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
