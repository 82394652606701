import { Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import type { IconType } from "octalog-base";

import classes from "./Feature.module.css";

export interface FeatureProps {
  title: string;
  description: string;
  Icon: IconType;
}

export function Feature({ title, description, Icon }: FeatureProps) {
  const theme = useMantineTheme();
  return (
    <div>
      <ThemeIcon className={classes.icon} color={theme.primaryColor} radius="md" size={44} variant="light">
        <Icon style={{ width: theme.fontSizes.xl, height: theme.fontSizes.xl }} />
      </ThemeIcon>
      <Text className={classes.title} fw={500} fz="lg">
        {title}
      </Text>
      <Text className={classes.description} fz="sm">
        {description}
      </Text>
    </div>
  );
}
