import "@mantine/core/styles.css";
import "./App.css";

import { MantineProvider } from "@mantine/core";
import { octalogTheme } from "octalog-base";

import { Router } from "./Router";

export function App() {
  return (
    <MantineProvider theme={octalogTheme}>
      <Router />
    </MantineProvider>
  );
}
