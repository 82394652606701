import { rem } from "@mantine/core";

import type { LogoProps } from "./OctalogLogo";
import { useOctalogLogoColors } from "./use-octalog-logo-colors";

export function OctalogLogoBlank({ size, color, style, ...others }: LogoProps) {
  const colors = useOctalogLogoColors({ color });

  return (
    <svg
      {...others}
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 125 125"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-571,-291)">
        <g>
          <path
            d="M633.5,414.8l-43.6-18l-18.1-43.6l18-43.6l43.6-18.1l43.6,18l18.1,43.6l-18,43.6L633.5,414.8z M597.7,
                  389.1 l35.8,14.8l35.8-14.9l14.8-35.8l-14.9-35.8l-35.8-14.8l-35.8,14.9l-14.8,35.8L597.7,389.1z"
            fill={colors.color.toString()}
          />
        </g>
        <g>
          <polygon
            fill={colors.color.toString()}
            points="633.6,392.8 605.6,381.2 599.8,367.2 609.1,363.3 613.3,373.5 633.6,381.8 653.8,373.4 662.2,
                     353.2 653.8,332.9 633.5,324.6 623.4,328.8 619.5,319.4 633.5,313.6 661.6,325.2 673.2,353.2 661.6,
                     381.2"
          />
        </g>
        <g>
          <polygon
            fill={colors.color.toString()}
            points="633.6,371.7 620.5,366.3 615.1,353.2 620.5,340.1 633.6,334.7 646.7,340.1 649.4,346.6 640.1,
                     350.5 638.9,347.9 633.6,345.6 628.3,347.9 626.1,353.2 628.3,358.5 633.6,360.8 636.3,359.6 640.2,369"
          />
        </g>
      </g>
    </svg>
  );
}
