import { Container, Stack, Text, Title } from "@mantine/core";

export function ImpressumPage() {
  return (
    <Container py="xl" size="md">
      <Stack>
        <Title order={1}>Impressum</Title>

        <Text>
          Marcel Richter
          <br />
          Octalog, Marcel Richter
          <br />
          Beckerstr. 33
          <br />
          64289 Darmstadt
        </Text>

        <Title order={2}>Kontakt</Title>
        <Text>
          Telefon: +49 (0) 175 4522 837
          <br />
          E-Mail: info@octalog.de
        </Text>

        <Title order={2}>Umsatzsteuer-ID</Title>
        <Text>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
          <br />
          DE 368785680
        </Text>

        <Title order={2}>EU-Streitschlichtung</Title>
        <Text>
          Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
          <br />
          <a href="https://ec.europa.eu/consumers/odr/" rel="noopener noreferrer" target="_blank">
            https://ec.europa.eu/consumers/odr/
          </a>
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
          <br />
        </Text>

        <Title order={2}>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Title>
        <Text>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </Text>

        <Title order={2}>Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung (EU) 2022/265)</Title>
        <Text>
          Unsere zentrale Kontaktstelle f&uuml;r Nutzer und Beh&ouml;rden nach Art. 11, 12 DSA erreichen Sie wie folgt:
        </Text>
        <Text>
          E-Mail: info@octalog.de
          <br />
          Telefon: +491754522837
        </Text>
        <Text>Die für den Kontakt zur Verf&uuml;gung stehenden Sprachen sind: Deutsch, Englisch.</Text>
      </Stack>
    </Container>
  );
}
