import { Grid } from "@mantine/core";

import type { FeatureProps } from "./Feature";
import { Feature } from "./Feature";

interface FeatureListProps {
  features: FeatureProps[];
  textAlign: "left" | "right";
}

export function FeatureList({ features, textAlign }: FeatureListProps) {
  return (
    <Grid align="stretch" justify={textAlign === "left" ? "flex-start" : "flex-end"}>
      {features.map((feature) => (
        <Grid.Col key={feature.title} span={{ base: 12, md: 6 }} ta={textAlign}>
          <Feature {...feature} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
