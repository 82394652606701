import type { MantineColor } from "@mantine/core";
import type React from "react";

import { OctalogLogoBlank } from "./OctalogLogoBlank";
import { OctalogLogoText } from "./OctalogLogoText";

export interface LogoProps extends React.ComponentPropsWithoutRef<"svg"> {
  color?: MantineColor;
  size?: number | string;
}

export interface OctalogLogoProps extends LogoProps {
  type?: "blank" | "text";
}

export function OctalogLogo({ type, color, ...others }: OctalogLogoProps) {
  const logoColor = color || "var(--mantine-color-text)";
  if (type === "blank") {
    return <OctalogLogoBlank color={logoColor} {...others} />;
  }
  return <OctalogLogoText color={logoColor} {...others} />;
}
