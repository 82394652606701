import type { BoxProps, ElementProps } from "@mantine/core";
import { Box, Container } from "@mantine/core";
import React from "react";

import classes from "./PageSection.module.css";

export interface PageSectionProps extends Omit<BoxProps, "id">, ElementProps<"div"> {
  id: string;
  children: React.ReactNode;
  alt?: boolean;
  divider?: boolean;
  containerProps?: React.ComponentProps<typeof Container>;
  sectionRefs: React.RefObject<Record<string, HTMLDivElement | null>>;
}

export function PageSection({
  id,
  children,
  alt,
  containerProps,
  divider = false,
  sectionRefs,
  ...others
}: PageSectionProps) {
  return (
    <Box
      className={classes.wrapper}
      data-alt={alt || undefined}
      data-border={divider ? true : undefined}
      id={id}
      ref={(el) => (sectionRefs.current![id] = el)}
      {...others}
    >
      <Container {...containerProps}>{children}</Container>
    </Box>
  );
}
