import type { GridProps, ImageProps } from "@mantine/core";
import { Grid, Image, Modal, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useMemo, useState } from "react";

import type { FeatureProps } from "../Feature/Feature";
import { FeatureList } from "../Feature/FeatureList";
import { PageSectionTitle } from "../PageSection/PageSectionTitle/PageSectionTitle";

import classes from "./FeaturesPageSection.module.css";

export interface FeaturesPageSectionProps extends Omit<GridProps, "children"> {
  title: string;
  description: string;
  mode: "horizontal" | "vertical";
  imageProps: ImageProps;
  features: FeatureProps[];
  reverse?: boolean;
}

export function FeaturesPageSection({
  title,
  description,
  reverse = false,
  mode = "horizontal",
  imageProps,
  features,
  ...others
}: FeaturesPageSectionProps) {
  const theme = useMantineTheme();
  const isSmallerThanLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [imagePreviewOpened, setImagePreviewOpened] = useState(false);

  const textAlign = useMemo(() => {
    return reverse || isMobile ? "left" : "right";
  }, [reverse, isMobile]);

  const renderTitle = () => (
    <Grid.Col span={12} ta={textAlign}>
      <PageSectionTitle position={textAlign}>{title}</PageSectionTitle>
    </Grid.Col>
  );

  const renderDescription = () =>
    description ? (
      <Grid.Col mb={30} span={12} ta={textAlign}>
        <Text c="dimmed" className={classes.description} fw={500} size="xl" span>
          {description}
        </Text>
      </Grid.Col>
    ) : null;

  const renderTitleAndDescription = () => (
    <Grid>
      {renderTitle()}
      {renderDescription()}
    </Grid>
  );

  const renderImage = () => (
    <>
      <Image
        alt={title}
        className={classes.image}
        fit="contain"
        height="auto"
        onClick={() => setImagePreviewOpened(true)}
        radius="md"
        style={{ cursor: "pointer" }}
        width="auto"
        {...imageProps}
      />
      <Modal
        centered
        fullScreen
        onClose={() => setImagePreviewOpened(false)}
        opened={imagePreviewOpened}
        padding={0}
        styles={{
          content: {
            background: "rgba(0, 0, 0, 0.9)",
          },
          inner: {
            padding: 0,
          },
        }}
      >
        <Image alt={title} fit="contain" style={{ ...getImageStyle(), ...imageProps.style }} {...imageProps} />
      </Modal>
    </>
  );

  const getImageStyle = () => {
    if (isMobile) {
      return { objectFit: "contain" as const };
    }
    return {
      maxWidth: "100vw",
      maxHeight: "100vh",
      objectFit: "contain" as const,
    };
  };

  const imageSpan = isMobile ? 12 : mode === "vertical" ? 3 : 7;

  const renderContent = () => {
    const imageColumn = (
      <Grid.Col className={classes.wrapper} key="image-column" order={isMobile ? 2 : reverse ? 1 : 2} span={imageSpan}>
        {renderImage()}
      </Grid.Col>
    );

    const textColumn = (
      <Grid.Col key="text-column" order={isMobile ? 1 : reverse ? 2 : 1} span="auto">
        {mode === "vertical" ? renderTitleAndDescription() : null}
        <FeatureList features={features} textAlign={textAlign} />
      </Grid.Col>
    );

    return (
      <Grid align="center" gutter={isSmallerThanLg ? "xl" : "xl"} justify="center" px={10} {...others}>
        {mode === "horizontal" ? <Grid.Col span={12}>{renderTitleAndDescription()}</Grid.Col> : null}
        {reverse ? [textColumn, imageColumn] : [imageColumn, textColumn]}
      </Grid>
    );
  };

  return renderContent();
}
